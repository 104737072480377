<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mt-25">
          Purple MDM
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Multi-factor Login 🔐
          </b-card-title>
          <b-card-text class="mb-2">
            Please set up your multi-factor authentication below. Scan the code with your authenticator app.
          </b-card-text>

          <b-img v-if="qrCode !== ''" :src="qrCode" fluid alt="Responsive image" />

          <!-- form -->
          <validation-observer ref="tfaValidation">
            <b-form
              class="two-factor-authorise-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Verify your MFA Code"
                label-for="otp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="MFA Code"
                  rules="required"
                >
                  <b-form-input
                    id="otp"
                    v-model="otp"
                    :state="errors.length > 0 ? false:null"
                    name="otp"
                    placeholder="MFA Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Show login errors -->
              <b-alert v-model="showErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ loginError }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Verify Code
              </b-button>

              <b-link href="#" class="pt-1 position-absolute" @click="cancelLogin()">
                <small>Cancel Login</small>
              </b-link>

            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import auth from '@/auth/auth'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config';

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      code: '',
      qrCode: '',
      otp: '',
      loginError: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
    }
  },
  computed: {
    showErrors() {
      return this.loginError !== ''
    },
    logoUrl() {
      return require('@/assets/images/logo/logo.svg')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async mounted() {
    await this.checkToken()
    this.getCode()
  },
  methods: {
    checkToken() {
      const token = auth.getToken()
      // This page only works if they have just logged in.
      if (token === null) {
        this.$router.push({ name: 'auth-login' })
      }
    },
    getCode() {
      // Get a code for this user
      auth.getCode().then(async response => {
        this.qrCode = response.data.image
        this.code = response.data.code
      })
      .catch(error => {
        this.loginError = error
      })
    },
    validationForm() {
      this.$refs.tfaValidation.validate().then(success => {
        if (success) {
          auth.verify({
            otp: this.otp,
          }).then(async response => {
              auth.setToken(response.data.accessToken)

              // Response must be authorised so we should be able to use the refresh token to fetch the user details
              await auth.refreshCurrentUser()

              // Make sure they have some permissions
              if (auth.getCurrentUser().permissions == null) {
                this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
                return
              }

              this.$router.replace('/')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${auth.getCurrentUser().name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged in. Now you can start to explore!',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.tfaValidation.setErrors(error.response.data.error)
                })
            }, error => {
              if (error.response) {
                this.loginError = error.response.data.error
              } else {
                this.loginError = error.message
              }
            })
        }
      })
    },
    cancelLogin() {
      // Remove userData from store
      this.$store.dispatch('user/clearUser')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
