import moment from 'moment';

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorIsInPast = time => {
  if (!time || !moment(time).isValid()) {
    return false
  }
  return moment(time).isBefore(moment())
}

export const validatorIsAudioFile = file => {
  if (!file?.type) {
    return false
  }
  const validTypes = ['audio/mpeg', 'audio/mp4', 'audio/x-m4a', 'video/mp4', 'video/webm']
  return validTypes.includes(file.type)
}
